@font-face {
  font-family: 'Hellix-Regular';
  src: url('./assets/fonts/Hellix/Hellix-Regular.woff2');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Hellix-SemiBold';
  src: url('./assets/fonts/Hellix/Hellix-SemiBold.woff2');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'Hellix-Bold';
  src: url('./assets/fonts/Hellix/Hellix-Bold.woff2');
  font-style: bold;
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: 'TiemposHeadline-Regular';
  src: url('./assets/fonts/Tiempos/TiemposHeadline-Regular.woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'TiemposHeadline-Semibold';
  src: url('./assets/fonts/Tiempos/TiemposHeadline-Semibold.woff');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'TiemposHeadline-Bold';
  src: url('./assets/fonts/Tiempos/TiemposHeadline-Bold.woff');
  font-style: bold;
  font-weight: 900;
  font-display: swap;
}

html {
  height: 100%;
}

body {
  min-height: 100vh;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/* react-slick dots */

.education-button__bar {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  padding: 4px;
  border-radius: 50px;
  width: 200px;
  text-align: center;
}
.education-button__bar li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 3px;
  padding: 0;
}
.education-button__bar li button {
  padding: 1px;
  border: none;
  background: 'white';
  color: transparent;
  cursor: pointer;
  display: block;
  margin-left: 8px;
  height: 8px;
  width: 8px;
  border-radius: 4px;
}
.education-button__bar li.slick-active button {
  background-color: #3152f5;
}

.discover-button__bar {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  padding: 4px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  width: 200px;
  text-align: center;
}
.discover-button__bar li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 3px;
  padding: 0;
}
.discover-button__bar li button {
  padding: 1px;
  border: none;
  background: #c1cae0;
  color: transparent;
  cursor: pointer;
  display: block;
  margin-top: 20px;
  margin-left: 16px;
  height: 8px;
  width: 8px;
  border-radius: 4px;
}
.discover-button__bar li.slick-active button {
  background-color: #3152f5;
}

/* FQA Link */
.intercom-content-link {
  color: white;
}
